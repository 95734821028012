
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KTFooter",
  props: ['ads'],
  setup(props) {
    //console.log(props.ads)
    return {
      props,
      footerWidthFluid,
    };
  },
});
