
import { defineComponent } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import store from "@/store";

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
  },
  data() {
    return {
      profileUrl:
        "https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=image&uid=" +
        store.state.uid,
      //tokenValue: localStorage.JWT_TOKEN,
      //uidValue: localStorage.uid,
      //usernameValue: store.state.username
    };
  },
  computed: {
    /*
    tokenValue () {
      return localStorage.getItem('JWT_TOKEN')?.toString()
    },
    */
    uidValue() {
      return store.state.uid;
    },
    usernameValue() {
      return store.state.username;
    },
  },
  watch: {
    uidValue() {
      this.profileUrl = "https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=image&uid=" + store.state.uid
    }
  },
  methods: {
    imageLoadError() {
      this.profileUrl = "https://php.rankopen.com/uc_server/data/avatar/image/default.png?version=1";
    },
  },
});
