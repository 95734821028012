
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const { t, te, i18n } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    //const MainMenuConfig = [];

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const toUrl = (path) => {
      return { name: path, params: { lang: route.params.lang, region: route.params.region, sport: route.params.sport } }
    };

    const changeLang = () => {
      var toggleLang = 'hk';
      if (route.params.lang == "en") {
        localStorage.setItem("lang", "hk");
        toggleLang = 'hk';
      } else if (route.params.lang == "hk") {
        localStorage.setItem("lang", "en");
        toggleLang = 'en';
      }
      return { name: route.params.name , params: { lang: toggleLang, region: route.params.region, sport: route.params.sport } }

    };

    const toPath = (path) => {
      return "/" + route.params.lang +'/'+route.params.region+'/'+route.params.sport+ path;
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      toPath,
      toUrl,
      changeLang
    };
  },
  data() {
    return {
      menuClicked: 0,
      menuClose: false
    };
  },
  methods: {
    clickClose () {
      this.menuClicked = this.menuClicked+1
      this.menuClose = false
      console.log(this.menuClose)
    }
  }
});
